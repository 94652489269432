export class animation {
    constructor() {
        let body = document.querySelector<HTMLBodyElement>('body');

        window.addEventListener(
            'scroll',
            () => {
                body?.style.setProperty('--illu-deg', window.scrollY / 10 + 'deg');
                body?.style.setProperty('--illu-px', window.scrollY / 10 + 'px');
            },
            { capture: false, passive: true }
        );
    }
}
