import { Collapse } from 'bootstrap';

export class navigation {
    constructor() {
        this.main();
    }

    main(): void {
        const mainNavigation = document.getElementById('nav-burger') as HTMLElement;
        const mainNavigationToggle = document.getElementById('nav-burger__button') as HTMLButtonElement;
        const mainNavigationCollapse = new Collapse(mainNavigation, {
            toggle: false,
        });

        mainNavigationToggle.addEventListener('click', (e) => {
            e.stopPropagation();
            e.preventDefault();
            mainNavigationCollapse.toggle();
            mainNavigationToggle.setAttribute(
                'aria-expanded',
                mainNavigationToggle.getAttribute('aria-expanded') === 'true' ? 'false' : 'true'
            );
        });

        document.addEventListener('keyup', (event: KeyboardEvent) => {
            if (mainNavigationToggle.getAttribute('aria-expanded') === 'true') {
                if (event.key == 'Escape') {
                    event.stopPropagation();
                    event.preventDefault();
                    mainNavigationCollapse.hide();
                    mainNavigationToggle.setAttribute('aria-expanded', 'false');
                    document.getElementById('top')?.classList.remove('nav-open');
                    const mainNavigationItems = document.querySelectorAll('.nav-main__list .toggle');
                    for (const mainNavigationItem of mainNavigationItems) {
                        mainNavigationItem.classList.remove('hidden');
                    }
                    mainNavigationToggle.querySelector('.toggle')?.classList.add('hidden');
                }
            }
        });

        mainNavigation?.addEventListener('show.bs.collapse', (event) => {
            let elem = event.target as HTMLElement;
            const mainNavigationItems = document.querySelectorAll('.nav-main__list .toggle');
            elem.classList.remove('d-none');
            document.getElementById('top')?.classList.add('nav-open');
            for (const mainNavigationItem of mainNavigationItems) {
                mainNavigationItem.classList.toggle('hidden');
            }
        });

        mainNavigation?.addEventListener('hide.bs.collapse', () => {
            document.getElementById('top')?.classList.remove('nav-open');
            const mainNavigationItems = document.querySelectorAll('.nav-main__list .toggle');
            for (const mainNavigationItem of mainNavigationItems) {
                mainNavigationItem.classList.toggle('hidden');
            }
        });
    }
}
