export function logger(name: string): ILogger {
    if (location.hostname.includes('dev') || location.hostname.includes('docker')) {
        return new DevLogger(name);
    }

    return new LiveLogger(name);
}

export interface ILogger {
    info(msg: string, ...args: any): void;
    error(msg: string, ...args: any): void;
}

class LiveLogger implements ILogger {
    protected prefix: string;

    constructor(prefix: string) {
        this.prefix = prefix;
    }

    info(_msg: string, ..._args: any): void {}

    error(msg: string, ...args: any): void {
        console.error(this.prefix + ': ' + msg, ...args);
    }
}

class DevLogger extends LiveLogger {
    override info(msg: string, ...args: any): void {
        console.log(this.prefix + ': ' + msg, ...args);
    }
}
