import { Supi } from '../../../../../vendor/supseven/supi/Build/Src/JavaScript/Supi';

import { navigation } from './components/navigation';
import { search } from './components/search';
import { animation } from './components/animation';

//global imports
import 'bootstrap/js/dist/modal';

function init() {
    (window as any).supi = new Supi();

    // navigation
    new navigation();

    // search form
    new search();

    //mobile pagination select redirect
    const paginationMobileSelect = document.getElementById('pagination-select') as HTMLSelectElement;
    if (paginationMobileSelect) {
        paginationMobileSelect.addEventListener('change', () => {
            window.location.href = paginationMobileSelect.value;
        });
    }

    // animations
    new animation();
}

if (['complete', 'interactive', 'loaded'].includes(document.readyState)) {
    init();
} else {
    document.addEventListener('DOMContentLoaded', () => init());
}
