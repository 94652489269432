import { Autocomplete } from '../lib/Autocomplete';
import { ILogger, logger } from '../helper/logger';

interface FacetsFilterOption {
    active: boolean;
    filter: string;
    label: string;
}
type FacetFilterSelect = FacetsFilterOption[];
type FacetFilterFormData = Record<string, FacetFilterSelect>;

export class search {
    private log: ILogger;
    constructor() {
        this.log = logger('search');
        this.setFilters();
        this.search();
        this.close();
    }

    search(): void {
        // search form handling
        document.querySelectorAll<HTMLFormElement>('.search-form').forEach((searchForm) => {
            const formWrapper = searchForm.parentElement as HTMLElement;
            const input = searchForm.querySelector<HTMLInputElement>('.search-form__input') as HTMLInputElement;

            if (searchForm) {
                Autocomplete.create(formWrapper, searchForm, input);
            }
        });
    }

    private async setFilters() {
        // GET BASE DATA AND SET CREATE DROPDOWNS TO FORMS
        const formDataSet =
            document.querySelector<HTMLDivElement>('[data-xhr-filter-update]')?.dataset.xhrFilterUpdate || '';

        if (formDataSet) {
            this.log.info('search constructor');
            const url = new URL(formDataSet);
            let data = this.getData(url, 'got all data');

            this.setFiltersToForms(await data);
            this.setLabels();

            //ON INPUT CHANGE UPDATE DATA AND SET TO FORM
            let updateUrl = new URL(formDataSet);
            document.querySelectorAll('.filter-form').forEach((form) => {
                form.querySelectorAll<HTMLInputElement>('input[data-filter-facet]').forEach((input): void => {
                    const filter = input.getAttribute('data-filter-facet');
                    const optAll = form.querySelector(
                        `input.form-check-input-all[data-filter-facet='${filter}']`
                    ) as HTMLInputElement;
                    const optOthers = form.querySelectorAll<HTMLInputElement>(
                        `input.form-check-input[data-filter-facet='${filter}']:not(.form-check-input-all)`
                    );

                    input.addEventListener('change', async () => {
                        // checkbox handling
                        if (input.classList.contains('form-check-input-all')) {
                            if (input.checked) {
                                optOthers.forEach((el) => {
                                    el.checked = false;
                                });
                            } else {
                                input.checked = true;
                            }
                        } else {
                            optOthers.forEach((el) => {
                                if (input.classList.contains('fromURL')) {
                                    return;
                                } else if (el != input && !el.classList.contains('fromURL')) {
                                    el.checked = false;
                                }
                            });

                            optAll.checked = activeCheckBoxExists(optOthers);
                        }

                        updateUrl.searchParams.delete(decodeURI('tx_solr%5Bfilter%5D%5B%5D'));
                        const allChecked = getAllChecked();

                        allChecked.forEach((checked) => {
                            updateUrl.searchParams.append(checked.name, checked.value);
                        });

                        let newData = this.getData(updateUrl, 'got new data because inputs changed');
                        this.setFiltersToForms(await newData, true);
                        this.setLabels();
                    });

                    function activeCheckBoxExists(optOthers: NodeListOf<HTMLInputElement>): boolean {
                        let empty: Array<boolean> = [].filter.call(optOthers, function (el: HTMLInputElement) {
                            return !el.checked;
                        });

                        return optOthers.length == empty.length;
                    }
                });
            });

            // SET FORM FILTER FROM URL
            const urlParams = new URLSearchParams(window.location.search);

            if (urlParams && !urlParams.get('tx_solr[q]')) {
                let activeFacets: Array<string> = [];

                urlParams.forEach((value, name) => {
                    activeFacets.push(value.substring(0, value.indexOf(':')));
                    updateUrl.searchParams.append(name, value);
                });

                document.querySelectorAll<HTMLInputElement>('input[data-filter-facet]').forEach((input): void => {
                    urlParams.forEach((param) => {
                        if (input.value === param) {
                            input.classList.add('fromURL');
                            input.checked = true;
                        }
                    });
                });

                activeFacets.forEach((filter) => {
                    document
                        .querySelectorAll<HTMLInputElement>(`input.form-check-input-all[data-filter-facet='${filter}']`)
                        .forEach((input): void => {
                            input.checked = false;
                        });
                });

                let newData = this.getData(updateUrl, 'got new data because inputs changed');
                this.setFiltersToForms(await newData, true);
                this.setLabels();
            }

            function getAllChecked(): NodeListOf<HTMLInputElement> {
                return document.querySelectorAll<HTMLInputElement>('input[data-filter-facet]:checked');
            }
        }
    }

    private setFiltersToForms(data: FacetFilterFormData, update: boolean = false) {
        if (update) {
            let filters: string[] = [];
            Object.keys(data).forEach((field) => {
                if (data[field]) {
                    data[field].forEach((optData: FacetsFilterOption): void => {
                        filters.push(optData['filter']);
                    });
                }
            });

            document.querySelectorAll<HTMLFormElement>('.filter-form').forEach((form) => {
                this.log.info('now changing filters on: ', form);
                form.querySelectorAll('li.select-item').forEach((li) => {
                    const input = li.querySelector('input.form-check-input') as HTMLInputElement;
                    li.classList.remove('hidden');
                    if (input.value) {
                        if (!filters.includes(input.value)) {
                            input.checked = false;
                            li?.classList.add('hidden');
                        }
                    }
                });
            });
        } else {
            document.querySelectorAll<HTMLFormElement>('.filter-form-list').forEach((form) => {
                for (const filter in data) {
                    const wrapper = form.querySelector('.' + filter + ' .select-items') as HTMLUListElement;
                    if (wrapper) {
                        data[filter].forEach(function (option, i: number) {
                            const nodeEl = document.createElement('li');
                            nodeEl.classList.add('select-item');
                            let idMobile: string = '';
                            if (form.classList.contains('filter-form-list__mobile')) {
                                idMobile = '-mobile';
                            }
                            nodeEl.innerHTML = `
                                    <label
                                        class="form-check-label | w-100 d-flex flex-row justify-content-between align-items-center py-hg px-qp"
                                        for="opt-${filter}-${i + 1}${idMobile}"
                                    >
                                        ${option.label}
                                        <input ${option.active ? 'checked' : ''}
                                            class="form-check-input form-check-input-${filter} | flex-shrink-0 ms-hg"
                                            type="checkbox"
                                            data-filter-facet="${filter}"
                                            name="tx_solr[filter][]"
                                            id="opt-${filter}-${i + 1}${idMobile}" value="${option.filter}">
                                    </label>
                                `;
                            wrapper.appendChild(nodeEl);
                        });
                    }
                }
            });
        }
    }

    private setLabels(): void {
        document.querySelectorAll<HTMLDivElement>('.filter-form .select-wrapper').forEach((wrapper) => {
            const activeEls = wrapper.querySelectorAll('.form-check-input:checked') as NodeListOf<HTMLInputElement>;
            const label = wrapper.querySelector('.select-header span') as HTMLSpanElement;
            label.innerText = '';
            let index = 0;
            activeEls.forEach((activeEl) => {
                if (index > 0 && activeEl.labels) label.innerText += ',\xa0';
                if (activeEl.labels) label.innerText += activeEl.labels[0].innerText.trim();
                index++;
            });
        });
    }

    close(): void {
        // close all collapsible el if click outside or ESC pressed
        document.addEventListener('click', function (e) {
            document
                .querySelectorAll<HTMLAnchorElement>('.select-header[aria-expanded="true"]')
                .forEach((openElToggle) => {
                    const id = openElToggle.getAttribute('aria-controls');
                    if (id) {
                        const el = document.getElementById(id) as HTMLDivElement;
                        if (el.contains(e.target as Node)) {
                        } else {
                            openElToggle?.click();
                        }
                    }
                });
        });

        document.addEventListener('keyup', function (e) {
            if (e.key === 'Escape') {
                document.querySelectorAll<HTMLDivElement>('[aria-expanded="true"]').forEach((openElToggle) => {
                    e.stopPropagation();
                    e.preventDefault();
                    openElToggle.click();
                });
            }
        });
    }

    private async getData(url: URL, msg: string) {
        const data: FacetFilterFormData = await (await fetch(url)).json();
        this.log.info(msg, data);
        return data;
    }
}
